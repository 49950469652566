import { Input } from "@chakra-ui/react";
import React from "react";

import { InputRenderer } from "../types";

interface InputProps extends InputRenderer {
  as?: React.ElementType;
}

const DefaultInput = ({
  as,
  inputProps,
  invalid,
  inputType,
  placeholder,
}: InputProps) => (
  <Input
    as={as}
    isInvalid={invalid}
    placeholder={placeholder}
    type={inputType}
    {...inputProps}
    value={inputProps.value ?? ""}
  />
);

export default DefaultInput;
