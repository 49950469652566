import React, { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";

const Wrapper = __CLIENT__ ? BrowserRouter : StaticRouter;

interface RouterProps extends PropsWithChildren {
  location: string;
}

const Router = ({ children, location }: RouterProps) => (
  <Wrapper location={location}>{children}</Wrapper>
);

export default Router;
