import { useEffect } from "react";

import {
  DefinitionForType,
  EmptyResource,
  ResourceType,
} from "../../types/serializers";

import useRefreshResource from "./useRefreshResource";
import useResource from "./useResource";

const useFreshResource = <
  T extends ResourceType,
  D extends DefinitionForType<T> = DefinitionForType<T>
>(
  resource: Partial<EmptyResource<T>> | undefined,
  opts?: { root?: string }
): D | undefined => {
  const refreshResource = useRefreshResource();

  useEffect(() => {
    if (resource?.type && resource?.id) {
      refreshResource(resource as EmptyResource, opts?.root);
    }
  }, [resource?.type, resource?.id]);

  return useResource(resource, opts);
};

export default useFreshResource;
