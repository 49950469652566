import React from "react";

import { ResourceType } from "../../../types/serializers";

import CheckboxesInput from "./inputTypes/CheckboxesInput";
import CheckboxInput from "./inputTypes/CheckboxInput";
import DefaultInput from "./inputTypes/DefaultInput";
import FileInput from "./inputTypes/FileInput";
import SearchInput from "./inputTypes/SearchInput";
import SelectInput from "./inputTypes/SelectInput";
import TextAreaInput from "./inputTypes/TextAreaInput";
import { InputRenderer, InputTypes } from "./types";

const OmniInput = <T extends ResourceType>(props: InputRenderer<T>) => {
  switch (props.inputType) {
    case InputTypes.Checkbox:
      return <CheckboxInput {...props} />;
    case InputTypes.Checkboxes:
      return <CheckboxesInput {...props} />;
    case InputTypes.File:
      return <FileInput {...props} />;
    case InputTypes.Search:
      return <SearchInput {...props} />;
    case InputTypes.Select:
      return <SelectInput {...props} />;
    case InputTypes.TextArea:
      return <TextAreaInput {...props} />;
    default:
      return <DefaultInput {...props} />;
  }
};

export default OmniInput;
