import { Modal, ModalOverlay } from "@chakra-ui/react";
import React, { FC, useContext } from "react";
import ReactDom from "react-dom";

import { ModalContext, ModalRenderSharedProps } from "./ModalContextProvider";

interface ModalPortal<P, T = Omit<P, "handleClose">>
  extends ModalRenderSharedProps {
  modal: FC<T & ModalRenderSharedProps>;
  modalProps: T;
}

const ModalPortal = <P,>({
  handleClose,
  modal: Component,
  modalProps,
}: ModalPortal<P>) => {
  const [_, containerRef] = useContext(ModalContext);

  if (!containerRef?.current) {
    return null;
  }

  return ReactDom.createPortal(
    <Modal isCentered isOpen onClose={handleClose}>
      <ModalOverlay />
      <Component handleClose={handleClose} {...modalProps} />
    </Modal>,
    containerRef.current
  );
};

export default ModalPortal;
