import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import React, { Fragment, useContext } from "react";

import { CollectionContext } from "../../../shared/components/collection";
import CollectionFilter from "../../../shared/components/collection/CollectionFilter";
import { collectionOptsFromId } from "../../../shared/lib/ids";

interface CollectionFiltersProps {
  accordion?: boolean;
}

const CollectionFilters = ({ accordion }: CollectionFiltersProps) => {
  const { availableFilters, collectionId, updateCollection } =
    useContext(CollectionContext);

  const params = collectionOptsFromId(collectionId);
  const currentFilters = (params.filter ?? {}) as Record<string, string[]>;

  const handleFilterChange = (attr: string) => (value: string[]) => {
    const newFilter = { ...params.filter } as Record<string, string[]>;
    newFilter[attr] = value;

    updateCollection({ filter: newFilter });
  };

  if (accordion) {
    return (
      <>
        {availableFilters?.map((option) => (
          <AccordionItem key={option.attribute}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  {option.label}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CollectionFilter
                {...option}
                value={currentFilters[option.attribute] ?? []}
                onChange={handleFilterChange(option.attribute)}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </>
    );
  }

  return (
    <Fragment>
      {availableFilters?.map((option) => (
        <FormControl flexGrow={1} key={option.label}>
          <FormLabel>{option.label}</FormLabel>
          <CollectionFilter
            {...option}
            value={currentFilters[option.attribute] ?? []}
            onChange={handleFilterChange(option.attribute)}
          />
        </FormControl>
      ))}
    </Fragment>
  );
};

export default CollectionFilters;
