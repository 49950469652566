import { Spinner, chakra } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import ReactPlayer from "react-player/lazy";

import useIsMounted from "../../hooks/useIsMounted";

const VIDEO_WIDTH = 640;
const VIDEO_HEIGHT = 360;

interface VideoPlayer extends PropsWithChildren {
  url: string;
}

const VideoPlayer = ({ url }: VideoPlayer) => {
  const mounted = useIsMounted();

  return (
    <chakra.div
      background="darkgray"
      display="flex"
      height={VIDEO_HEIGHT}
      maxWidth="100%"
      width={VIDEO_WIDTH}
    >
      {mounted ? (
        <ReactPlayer height={VIDEO_HEIGHT} url={url} width={VIDEO_WIDTH} />
      ) : (
        <Spinner
          emptyColor="gray.200"
          height="10em"
          margin="auto"
          speed="1s"
          thickness="0.5em"
          width="10em"
        />
      )}
    </chakra.div>
  );
};

export default VideoPlayer;
