import React from "react";

import Highlight from "../../../highlight";
import {
  ExtensionType,
  HTMLParser,
  MarkedExtension,
  RenderElement,
  Tokens,
} from "../types";

const REGEX = /^<aside>([\s\S]*?[^(</aside>)])<\/aside>/;

export const highlightMarked: MarkedExtension = {
  level: "block",
  name: ExtensionType.highlight,
  renderer(token): string {
    return `<aside>${this.parser.parse(token.tokens ?? [])}</aside>`;
  },
  start(src: string) {
    return src.match(REGEX)?.index;
  },
  tokenizer(src: string): void | Tokens.Generic {
    const match = src.match(REGEX);
    if (match) {
      const token = {
        raw: match[0],
        text: match[1].trim(),
        tokens: [],
        type: ExtensionType.highlight,
      };

      this.lexer.blockTokens(token.text, token.tokens);

      return token;
    }

    return undefined;
  },
};

export const parseHighlightHTML: HTMLParser = () => ({
  type: ExtensionType.highlight,
});

export const renderHighlight = (props: RenderElement) => {
  const { attributes, children } = props;

  return (
    <Highlight {...attributes} margin="2em 0">
      {children}
    </Highlight>
  );
};

export const highlightMarkdown = (_chunk: unknown, children: string) => {
  return `<aside>${children}</aside>\n`;
};
