import {
  Box,
  Card,
  CardBody,
  Heading,
  Image,
  LinkBox,
  SkeletonText,
  Stack,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

import { ResourceType } from "../../../types/serializers";
import useResource from "../../hooks/useResource";
import Link from "../link";
import SimpleMarkdown from "../simpleMarkdown";
import useResourceRoute from "../store/hooks/useResourceRoute";

const IMAGE_HEIGHT = {
  base: "8em",
  sm: "10em",
  md: "12em",
  lg: "10em",
  xl: "10em",
};
const IMAGE_WIDTH = {
  base: "8em",
  sm: "10em",
  md: "14em",
  lg: "10em",
  xl: "12em",
};

const BlogInline = ({ linked, slug }: { linked: boolean; slug: string }) => {
  const blog = useResource({ id: slug, type: ResourceType.Blog });
  const coverImage = useResource(blog?.relationships.coverImage.data);
  const href = useResourceRoute(blog);

  return (
    <Card
      _hover={{ borderColor: "gray.dark" }}
      as={LinkBox}
      background="background.card"
      borderColor="background.stroke"
      direction={{ base: "row", sm: "row" }}
      marginBottom="2em"
      marginTop="1em"
      overflow="hidden"
      variant="outline"
    >
      <Image
        alt={coverImage?.attributes.alt}
        background="gray.light"
        flexShrink={0}
        height={IMAGE_HEIGHT}
        objectFit="cover"
        src={coverImage?.attributes.coverUrl}
        width={IMAGE_WIDTH}
      />
      <Stack width="100%">
        <CardBody maxHeight={IMAGE_HEIGHT} width="100%">
          {blog ? (
            <>
              <Heading marginBottom="1em" size="md">
                <Link overlay href={linked ? href : undefined}>
                  {blog?.attributes.title}
                </Link>
              </Heading>
              <Box display={{ base: "none", sm: "block" }} textStyle="bodyBlog">
                <SimpleMarkdown>{blog?.attributes.intro ?? ""}</SimpleMarkdown>
                <Box
                  bgGradient="linear(to-b, rgba(244, 249, 255, 0) 0%, rgba(244, 249, 255, 1) 100%)"
                  bottom="0"
                  height="2em"
                  pointerEvents="none"
                  position="absolute"
                  width="100%"
                />
              </Box>
            </>
          ) : (
            <SkeletonText
              margin="auto"
              noOfLines={5}
              skeletonHeight="4"
              title={t("shared:status.loading")!}
              width="10em"
            />
          )}
        </CardBody>
      </Stack>
    </Card>
  );
};

BlogInline.defaultProps = {
  linked: true,
};

export default BlogInline;
