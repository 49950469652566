import { AsyncSelect as ChakraAsyncSelect } from "chakra-react-select";
import React from "react";
import { RefCallBack } from "react-hook-form";

import { EmptyResource, ResourceType } from "../../../types/serializers";
import useInstantSearch from "../../hooks/useInstantSearch";
import useStatus from "../../hooks/useStatus";
import useStore from "../../hooks/useStore";
import toString from "../../lib/toString";
import Store, { ResourceStatus } from "../store/Store";

const getOptionLabel = (store: Store) => (option: EmptyResource) =>
  toString(store.getResource(option.type, option.id));

interface AsyncSearchProps {
  inputRef?: RefCallBack;
  invalid?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange: (value: string | undefined) => void;
  placeholder?: string | null;
  resourceType: ResourceType;
  value?: string;
}

const AsyncSelect = ({
  inputRef,
  invalid,
  name,
  onBlur,
  onChange,
  placeholder,
  resourceType,
  value,
}: AsyncSearchProps) => {
  const store = useStore();
  const resourceValue = value ? { id: value, type: resourceType } : undefined;
  const valueStatus = useStatus(resourceValue, { fetch: true });
  const loadOptions = useInstantSearch(resourceType);

  return (
    <ChakraAsyncSelect<EmptyResource>
      defaultOptions
      getOptionLabel={getOptionLabel(store)}
      getOptionValue={(a) => a.id}
      isInvalid={invalid}
      loadOptions={loadOptions}
      name={name}
      placeholder={placeholder}
      ref={inputRef}
      value={
        [
          ResourceStatus.MarkedForRefresh,
          ResourceStatus.Present,
          ResourceStatus.Refreshing,
        ].includes(valueStatus)
          ? resourceValue
          : undefined
      }
      onBlur={onBlur}
      onChange={(option) => onChange(option?.id)}
    />
  );
};

export default AsyncSelect;
