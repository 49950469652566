import { IconButton, Input, InputGroup } from "@chakra-ui/react";
import React, { FormEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

import { trackEvent } from "../../lib/analytics";

import { CollectionContext } from "./index";

const CollectionSearch = () => {
  const { t } = useTranslation();
  const { attributes, updateCollection } = useContext(CollectionContext);
  const [value, setValue] = useState(attributes.query ?? "");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    updateCollection({
      query: value,
    });

    trackEvent("page_search", { query: value });
  };

  return (
    <form onSubmit={onSubmit}>
      <InputGroup>
        <Input
          borderLeftRadius="0.5em"
          borderRightRadius={0}
          placeholder={t("shared:search.label")!}
          type="search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <IconButton
          aria-label={t("shared:search.label")}
          border="1px solid"
          borderColor="gray.200"
          borderLeft="none"
          borderLeftRadius={0}
          icon={<FaSearch />}
          type="submit"
          variant="ghost"
        />
      </InputGroup>
    </form>
  );
};

export default CollectionSearch;
