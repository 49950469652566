import React, { MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaSlidersH } from "react-icons/fa";

import { collectionOptsFromId } from "../../lib/ids";
import Button from "../button";
import { ModalRenderSharedProps } from "../modal/ModalContextProvider";
import ModalPortal from "../modal/ModalPortal";

import CollectionSortFilterModal from "./CollectionSortFilterModal";

import { CollectionContext } from "./index";

const CollectionSortFilterButton = () => {
  const { t } = useTranslation();
  const collectionContext = useContext(CollectionContext);
  const { filter } = collectionOptsFromId(collectionContext.collectionId);

  const [showModal, setShowModal] = React.useState(false);
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setShowModal(true);
  };

  const filterCount = Object.keys(filter ?? {}).length;

  return (
    <>
      {showModal && (
        <ModalPortal<ModalRenderSharedProps & CollectionContext>
          handleClose={() => setShowModal(false)}
          modal={CollectionSortFilterModal}
          modalProps={collectionContext}
        />
      )}
      <Button
        aria-label={t("shared:collection.filter.label")!}
        marginRight={1}
        rightIcon={<FaSlidersH />}
        size="sm"
        variant="outline"
        onClick={handleClick}
      >
        {t("shared:collection.filter.label")}
        {filterCount > 0 && ` (${filterCount})`}
      </Button>
    </>
  );
};

export default CollectionSortFilterButton;
