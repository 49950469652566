import { ResponsiveValue } from "@chakra-ui/react";
import { Property } from "csstype";
import React, { useContext } from "react";

import {
  CollectionOrderDirection,
  DefinitionForType,
  EmptyResource,
  ResourceType,
} from "../../../types/serializers";
import { collectionOptsFromId } from "../../lib/ids";
import Table, { Column } from "../table";

import CollectionPagination from "./CollectionPagination";

import { CollectionContext } from "./index";

interface CollectionTableProps<T extends ResourceType> {
  columns: Column<T>[];
  onRowClick?: (item: DefinitionForType<T>) => void;
  showHead?: boolean;
  size?: string;
  variant?: string;
  whiteSpace?: ResponsiveValue<Property.WhiteSpace>;
  width?: string;
}

const CollectionTable = <T extends ResourceType>({
  columns,
  onRowClick,
  showHead,
  size,
  variant,
  whiteSpace,
  width,
}: CollectionTableProps<T>) => {
  const { collectionId, relationships, updateCollection } =
    useContext(CollectionContext);
  const { order } = collectionOptsFromId(collectionId);

  const handleSortChange = (
    value: string,
    direction: CollectionOrderDirection | undefined
  ) => {
    updateCollection({ order: direction ? { [value]: direction } : {} });
  };

  return (
    <>
      <Table<T>
        columns={columns}
        rows={relationships.items.data as EmptyResource<T>[]}
        showHead={showHead}
        size={size}
        sort={order}
        variant={variant}
        whiteSpace={whiteSpace}
        width={width}
        onRowClick={onRowClick}
        onSortChange={handleSortChange}
      />
      <CollectionPagination />
    </>
  );
};

export default CollectionTable;
