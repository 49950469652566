import {
  Table as ChakraTable,
  ResponsiveValue,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Property } from "csstype";
import React, { Fragment, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import {
  CollectionOrderDirection,
  DefinitionForType,
  EmptyResource,
  ResourceType,
  UnknownAttributes,
} from "../../../types/serializers";
import AsyncResources from "../async/AsyncResources";
import Link from "../link";

import HeadCell from "./HeadCell";

export interface Column<T extends ResourceType> {
  attribute: string;
  label?: string | null;
  link?: (resource: DefinitionForType<T>) => string | undefined;
  render?: (
    resource: DefinitionForType<T>
  ) => JSX.Element | string | null | undefined;
  sortable?: boolean;
  width?: string;
  wrap?: boolean;
}

interface TableProps<T extends ResourceType> {
  columns: Column<T>[];
  onRowClick?: (item: DefinitionForType<T>) => void;
  onSortChange: (
    value: string,
    direction: CollectionOrderDirection | undefined
  ) => void;
  rows: EmptyResource<T>[];
  showHead?: boolean;
  size?: string;
  sort?: Record<string, CollectionOrderDirection>;
  variant?: string;
  whiteSpace?: ResponsiveValue<Property.WhiteSpace>;
  width?: string;
}

const RowLoader = () => {
  const { t } = useTranslation();

  return (
    <Tr>
      <Td>{t("shared:status.loading")}</Td>
    </Tr>
  );
};

const ItemCell = <T extends ResourceType>({
  column,
  item,
}: {
  column: Column<T>;
  item: DefinitionForType<T>;
}) => {
  const Wrapper = column.link
    ? ({ children }: PropsWithChildren) => (
        <Link href={column.link!(item)}>{children}</Link>
      )
    : Fragment;

  return (
    <Td
      position="relative"
      whiteSpace={column.wrap ? "break-spaces" : "nowrap"}
      width={column.width}
    >
      <Wrapper>
        {column.render
          ? column.render(item)
          : (item.attributes as UnknownAttributes)[
              column.attribute
            ]?.toString()}
      </Wrapper>
    </Td>
  );
};

const Table = <T extends ResourceType>({
  columns,
  onRowClick,
  onSortChange,
  rows,
  showHead = true,
  size,
  sort,
  variant = "simple",
  whiteSpace,
  width,
}: TableProps<T>) => {
  const renderer = (item: DefinitionForType<T>) => (
    <Tr
      _hover={
        onRowClick
          ? { background: "gray.lighter", cursor: "pointer" }
          : undefined
      }
      key={item.id}
      onClick={onRowClick ? () => onRowClick(item) : undefined}
    >
      {columns.map((col) => {
        return <ItemCell column={col} item={item} key={col.attribute} />;
      })}
    </Tr>
  );

  return (
    <TableContainer>
      <ChakraTable
        size={size}
        variant={variant}
        whiteSpace={whiteSpace}
        width={width}
      >
        {showHead && (
          <Thead>
            <Tr>
              {columns.map((col) => {
                return (
                  <HeadCell<T>
                    key={col.attribute}
                    {...col}
                    currentSorting={sort?.[col.attribute]}
                    onSortChange={onSortChange}
                  />
                );
              })}
            </Tr>
          </Thead>
        )}
        <Tbody>
          <AsyncResources resources={rows} onLoad={RowLoader}>
            {renderer}
          </AsyncResources>
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};

export default Table;
