import { Box, Icon, Th, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

import {
  CollectionOrderDirection,
  ResourceType,
} from "../../../types/serializers";

import { Column } from "./index";

interface SortCaretProps {
  as: FC;
  attribute: string;
  currentSorting?: CollectionOrderDirection;
  direction: CollectionOrderDirection;
  label: string;
  onSortChange: (
    value: string,
    direction: CollectionOrderDirection | undefined
  ) => void;
}

const SortCaret = ({
  as,
  attribute,
  currentSorting,
  direction,
  label,
  onSortChange,
}: SortCaretProps) => {
  const active = currentSorting === direction;

  return (
    <Icon
      _hover={{ cursor: "pointer" }}
      aria-label={label}
      aria-selected={active}
      as={as}
      color={active ? "primary.darkActive" : "gray.main"}
      marginTop="0!important"
      onClick={() => onSortChange(attribute, active ? undefined : direction)}
    />
  );
};

interface HeadCellProps<T extends ResourceType> extends Column<T> {
  currentSorting?: CollectionOrderDirection;
  onSortChange?: (
    value: string,
    direction: CollectionOrderDirection | undefined
  ) => void;
}

const HeadCell = <T extends ResourceType>({
  attribute,
  currentSorting,
  label,
  sortable,
  onSortChange,
}: HeadCellProps<T>) => {
  if (!sortable || !onSortChange) {
    return <Th>{label}</Th>;
  }

  const handleClick = () => {
    if (!currentSorting) {
      onSortChange(attribute, CollectionOrderDirection.Asc);
    } else if (currentSorting === CollectionOrderDirection.Asc) {
      onSortChange(attribute, CollectionOrderDirection.Desc);
    } else {
      onSortChange(attribute, undefined);
    }
  };

  return (
    <Th>
      <Box alignItems="center" display="flex">
        <Box
          _hover={{ cursor: "pointer" }}
          userSelect="none"
          onClick={handleClick}
        >
          {label}
        </Box>
        <VStack flexShrink={0} gap={0} marginLeft="1em">
          <SortCaret
            as={FaCaretUp}
            attribute={attribute}
            currentSorting={currentSorting}
            direction={CollectionOrderDirection.Asc}
            label={t("shared:table.sort.asc")}
            onSortChange={onSortChange}
          />
          <SortCaret
            as={FaCaretDown}
            attribute={attribute}
            currentSorting={currentSorting}
            direction={CollectionOrderDirection.Desc}
            label={t("shared:table.sort.desc")}
            onSortChange={onSortChange}
          />
        </VStack>
      </Box>
    </Th>
  );
};
export default HeadCell;
