import { Box, HStack, Image } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";
import React, { PropsWithChildren } from "react";

import Tip from "../richText/lib/elements/icons/tip.svg";

const Highlight = ({
  children,
  icon,
  ...otherProps
}: PropsWithChildren<HTMLChakraProps<"aside"> & { icon?: boolean }>) => (
  <HStack
    {...otherProps}
    alignItems="normal"
    as="aside"
    background="background.banner"
    padding="1em"
  >
    {icon && <Image height={10} src={Tip} />}
    <Box paddingLeft={icon ? "0.5em" : undefined}>{children}</Box>
  </HStack>
);

export default Highlight;
