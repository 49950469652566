import React from "react";

import { trackEvent } from "../../../../lib/analytics";
import PensionProvider from "../../../pensionProvider";
import {
  ExtensionType,
  HTMLParser,
  MarkedExtension,
  PensionProviderNode,
  RenderElement,
  Tokens,
} from "../types";

const REGEX =
  /^<pension(\s*provider="(?<provider>[^"]*)")?(\s*url="(?<url>[^"]*)")?><\/pension>/;

export const pensionProviderMarked: MarkedExtension = {
  level: "block",
  name: ExtensionType.pensionProvider,
  renderer(token: { raw: string }): string {
    return token.raw;
  },
  start(src: string) {
    return src.match(REGEX)?.index;
  },
  tokenizer(src: string): void | Tokens.Generic {
    const match = src.match(REGEX);
    if (match) {
      return {
        provider: match.groups?.provider,
        raw: match[0],
        type: ExtensionType.pensionProvider,
        url: match.groups?.url,
      };
    }

    return undefined;
  },
};

export const parsePensionProviderHTML: HTMLParser = (el) => ({
  provider: el.attribs["provider"],
  type: ExtensionType.pensionProvider,
  url: el.attribs["url"],
});

export const renderPensionProvider = (props: RenderElement) => {
  const { attributes, element } = props;
  const { provider, url } = element as PensionProviderNode;
  const handleClick = () => {
    trackEvent("pension_click", { provider });
  };

  return (
    <PensionProvider
      attributes={attributes}
      provider={provider}
      url={url}
      onClick={handleClick}
    />
  );
};

export const pensionProviderMarkdown = (chunk: PensionProviderNode) => {
  const { provider, url } = chunk;

  return `<pension provider="${provider}" url="${url}"></pension>\n`;
};
