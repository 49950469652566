import React, { PropsWithChildren, useEffect } from "react";
import {
  Routes as ReactRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const Routes = ({ children }: PropsWithChildren) => {
  const navigationType = useNavigationType();
  const location = useLocation();

  useEffect(() => {
    if (navigationType == "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return <ReactRoutes>{children}</ReactRoutes>;
};

export default Routes;
