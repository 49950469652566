import {
  ButtonGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../button";
import { ModalRenderSharedProps } from "../modal/ModalContextProvider";

import CollectionSortFilter from "./CollectionSortFilter";

import { CollectionContext } from "./index";

const CollectionSortFilterModal = ({
  attributes,
  handleClose,
}: ModalRenderSharedProps & CollectionContext) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <ModalHeader>{t("shared:collection.filter.label")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <CollectionSortFilter />
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={handleClose}>
            {t("shared:collection.filter.continue", {
              count: attributes.totalCount,
            })}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  );
};

export default CollectionSortFilterModal;
