import { Params } from "react-router-dom";

import { EmptyResource, ResourceType } from "../../types/serializers";
import { Action } from "../components/store/Router";

import { collectionOptsFromQuery, getCollectionId } from "./ids";

export const resourceMapper: Record<
  Action,
  (
    params: Readonly<Params>,
    resourceType: ResourceType,
    queryParams: URLSearchParams
  ) => EmptyResource
> = {
  [Action.Edit]: (params, resourceType) => ({
    id: params.id!,
    type: resourceType,
  }),
  [Action.Index]: (_params, resourceType, queryParams) => ({
    id: getCollectionId(resourceType, collectionOptsFromQuery(queryParams)),
    type: ResourceType.Collection,
  }),
  [Action.New]: (_params, resourceType, queryParams) => ({
    id: getCollectionId(resourceType, collectionOptsFromQuery(queryParams)),
    type: ResourceType.Collection,
  }),
  [Action.Show]: (params, resourceType) => ({
    id: params.id!,
    type: resourceType,
  }),
};

export function retrievePath(originalUrl: undefined): undefined;
export function retrievePath(originalUrl: string): string;
export function retrievePath(originalUrl?: string): string | undefined {
  if (!originalUrl) {
    return undefined;
  }

  const url = new URL(originalUrl, "https://example.com");

  return url.pathname + url.search + url.hash;
}
