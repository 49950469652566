import { ControllerRenderProps } from "react-hook-form";
import { RefCallBack } from "react-hook-form/dist/types";

import { FormFieldDefinition } from "../../../types/forms";
import { ResourceType } from "../../../types/serializers";

export enum InputTypes {
  Association = "association",
  Checkbox = "checkbox",
  Checkboxes = "checkboxes",
  Date = "date",
  DateTime = "datetime-local",
  Email = "email",
  File = "file",
  Markdown = "markdown",
  MediaObject = "media_object",
  Number = "number",
  Search = "search",
  Select = "select",
  Text = "text",
  TextArea = "text_area",
}

export interface InputRenderer<T extends ResourceType = ResourceType>
  extends FormFieldDefinition<T> {
  inputProps: Omit<ControllerRenderProps, "ref"> & { ref?: RefCallBack };
  invalid?: boolean;
}
