import { Image as ChakraImage, chakra } from "@chakra-ui/react";
import React from "react";

interface ImageProps {
  alt: string;
  baseEncoded?: string;
  borderRadius?: string | number;
  caption?: string;
  height?: number;
  marginBottom?: string;
  opacity?: string;
  src: string;
  width?: number;
}

const Image = ({
  alt,
  baseEncoded,
  borderRadius,
  caption,
  height,
  marginBottom,
  src,
  width,
  opacity,
}: ImageProps) => (
  <chakra.figure marginBottom={marginBottom}>
    <ChakraImage
      alt={alt}
      background={baseEncoded ? undefined : "gray.light"}
      backgroundImage={baseEncoded}
      backgroundRepeat="no-repeat"
      backgroundSize="100% 100%"
      borderRadius={borderRadius}
      htmlHeight={height}
      htmlWidth={width}
      opacity={opacity}
      src={src}
      title={caption}
    />
    {caption && (
      <chakra.figcaption fontStyle="italic" textStyle="caption">
        {caption}
      </chakra.figcaption>
    )}
  </chakra.figure>
);

export default Image;
