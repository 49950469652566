import { chakra } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React, { useMemo } from "react";
import type { Descendant } from "slate";

import { parseHTML } from "./lib/parseHTML";
import { parseMarkdown } from "./lib/parseMarkdown";
import { renderSlateArray } from "./lib/render";

interface RichTextMarkdown {
  children: string;
  slateValue?: undefined;
  textStyle?: string;
}

interface RichTextSlate {
  children?: undefined;
  slateValue: Descendant[];
  textStyle?: string;
}
type RichTextProps = (RichTextMarkdown | RichTextSlate) & {
  itemProp?: string;
};

export const RICH_TEXT_CSS = `
  & > * {
    flex-basis: 100%;
  }
  align-content: start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
`;

const RichText = ({
  children,
  itemProp,
  slateValue,
  textStyle,
}: RichTextProps) => {
  const value = useMemo(
    () => slateValue ?? parseHTML(parseMarkdown(children)),
    [slateValue, children]
  );

  return (
    <chakra.div
      css={css(RICH_TEXT_CSS)}
      itemProp={itemProp}
      textStyle={textStyle}
    >
      {renderSlateArray(value)}
    </chakra.div>
  );
};

export default RichText;
