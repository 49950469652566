import React from "react";
import { RenderLeafProps } from "slate-react/dist/components/editable";

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  let formattedChildren = children == "\n" ? <br /> : children;

  if (leaf.strong) {
    formattedChildren = <strong>{formattedChildren}</strong>;
  }

  if (leaf.inlineCode) {
    formattedChildren = <code>{formattedChildren}</code>;
  }

  if (leaf.emphasis) {
    formattedChildren = <em>{formattedChildren}</em>;
  }

  if (leaf.strikeThrough) {
    formattedChildren = <del>{formattedChildren}</del>;
  }

  return <span {...attributes}>{formattedChildren}</span>;
};

export const renderLeaf = (props: RenderLeafProps) => {
  const { children, ...otherProps } = props;

  return <Leaf {...otherProps}>{props.leaf.text === "" ? "" : children}</Leaf>;
};

export const renderLeafEditor = (props: RenderLeafProps) => <Leaf {...props} />;
