import ABP from "./abp.png";
import BPF from "./bpf.png";
import BPL from "./bpl.png";
import PA from "./pa.png";
import PD from "./pd.png";
import PHC from "./phc.png";
import PME from "./pme.png";
import PMT from "./pmt.png";
import PSG from "./psg.png";
import PZW from "./pzw.png";
import SPW from "./spw.png";
import Stipp from "./stipp.png";

interface PensionProvider {
  image: string;
  label: string;
}

export const PensionProviders: Record<string, PensionProvider> = {
  abp: {
    image: ABP,
    label: "ABP",
  },
  bpf: {
    image: BPF,
    label: "bpfBOUW",
  },
  bpl: {
    image: BPL,
    label: "BPL pensioen",
  },
  pa: {
    image: PA,
    label: "Pensioenfonds voor de Architectenbureaus",
  },
  pd: {
    image: PD,
    label: "Pensioenfonds Detailhandel",
  },
  phc: {
    image: PHC,
    label: "Pensioenfonds Horeca & Catering",
  },
  pme: {
    image: PME,
    label: "Pensioenfonds voor de Metalektro",
  },
  pmt: {
    image: PMT,
    label: "Pensioenfonds Metaal & Techniek",
  },
  psg: {
    image: PSG,
    label: "Pensioenfonds Schoonmaak- en Glazenwassersbedrijf",
  },
  pzw: {
    image: PZW,
    label: "Pensioenfonds Zorg & Welzijn",
  },
  spw: {
    image: SPW,
    label: "Pensioenfonds voor de Woningcorporaties",
  },
  stipp: {
    image: Stipp,
    label: "Stichting Pensioenfonds voor Personeelsdiensten (StiPP)",
  },
};
