import { AppendID, NullToAny } from "../../types/helpers";
import {
  DefinitionForType,
  EmptyResource,
  ResourceType,
} from "../../types/serializers";

import useFetchCallback, { FetchResult, METHOD } from "./useFetchCallback";
import useStore from "./useStore";

export type ResourceData<T extends ResourceType> = Partial<
  NullToAny<
    DefinitionForType<T>["attributes"] extends object
      ? DefinitionForType<T>["attributes"]
      : object
  > &
    Record<keyof AppendID<DefinitionForType<T>["relationships"]>, string | null>
>;

type Callback<T extends ResourceType> = (
  data?: ResourceData<T>,
  onSuccess?: CallbackSuccessHandler<T>,
  onError?: CallbackErrorHandler<T>
) => void;

export type ResourceCallback<T extends ResourceType> = [
  callback: Callback<T>,
  pending: boolean
];

export type CallbackErrorHandler<T extends ResourceType | unknown = unknown> = (
  body: FetchResult<T>
) => void;

export type CallbackSuccessHandler<T extends ResourceType | unknown = unknown> =
  (body: FetchResult<T>) => void;

function useResourceCallback(
  url: undefined,
  method: METHOD,
  resourceIdentifier:
    | EmptyResource
    | { id?: undefined; type: ResourceType }
    | undefined,
  root?: string
): [];
function useResourceCallback<T extends ResourceType>(
  url: string,
  method: METHOD,
  resourceIdentifier:
    | EmptyResource
    | { id?: undefined; type: ResourceType }
    | undefined,
  root?: string
): ResourceCallback<T>;
function useResourceCallback<T extends ResourceType>(
  url: string | undefined,
  method: METHOD,
  resourceIdentifier:
    | EmptyResource
    | { id?: undefined; type: ResourceType }
    | undefined,
  root?: string
): [] | ResourceCallback<T>;
function useResourceCallback<T extends ResourceType>(
  path: string | undefined,
  method: METHOD,
  resourceIdentifier:
    | EmptyResource
    | { id?: undefined; type: ResourceType }
    | undefined,
  root = GLOBALS.root
): [] | ResourceCallback<T> {
  const store = useStore();

  const url = root + path;

  const [callback, pending] = useFetchCallback(url, method);

  if (!url || !resourceIdentifier) {
    return [];
  }

  const resourceCallback = (
    attributes?: ResourceData<T>,
    handleSuccess?: CallbackSuccessHandler<T>,
    handleError?: CallbackErrorHandler<T>
  ) => {
    const handleSuccessWithStorage = ({ json, response }: FetchResult<T>) => {
      store.processData(json, root);

      if (handleSuccess) {
        handleSuccess({ json, response });
      }
    };

    callback(
      {
        data: {
          attributes,
          id: resourceIdentifier.id,
          type: resourceIdentifier.type,
        },
      },
      handleSuccessWithStorage as CallbackSuccessHandler,
      handleError as CallbackErrorHandler
    );
  };

  return [resourceCallback, pending];
}

export default useResourceCallback;
