import {
  Checkbox,
  CheckboxGroup,
  Flex,
  IconButton,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import React, { FormEvent, KeyboardEvent, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronRight, FaPlus, FaTimes } from "react-icons/fa";

import useOptions from "../hooks/useOptions";
import { InputRenderer } from "../types";

const CheckboxesInput = ({
  creatable,
  inputProps,
  options,
  selectClass,
  useOptions: useOptionsHook,
}: InputRenderer) => {
  const { ref, ...otherInputProps } = inputProps;
  const { t } = useTranslation();
  const [selectOptions, addOption] = useOptions(
    selectClass,
    options,
    useOptionsHook
  );
  const [showNewOption, toggleShowNewOption] = useReducer(
    (prev) => !prev,
    false
  );
  const [newOption, setNewOption] = useState("");
  const handleNewOption = (e: FormEvent | KeyboardEvent) => {
    e.preventDefault();

    addOption({ key: newOption, label: newOption });
    inputProps.onChange([...inputProps.value, newOption]);
    setNewOption("");
    toggleShowNewOption();
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleNewOption(e);
    }
  };

  return (
    <>
      <CheckboxGroup {...otherInputProps}>
        <Flex direction={["column", "row"]} flexWrap="wrap">
          {selectOptions.map((option) => (
            <Checkbox
              key={option.key}
              marginRight="1em"
              value={option.key}
              whiteSpace="nowrap"
            >
              {option.label}
            </Checkbox>
          ))}
          {creatable && !showNewOption && (
            <IconButton
              aria-label={t("shared:inputs.checkboxes.addOption")!}
              colorScheme="gray"
              icon={<FaPlus />}
              size="sm"
              onClick={toggleShowNewOption}
            />
          )}
        </Flex>
      </CheckboxGroup>
      {showNewOption && (
        <InputGroup>
          <Input
            placeholder={t("shared:inputs.checkboxes.addOption")!}
            type="text"
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <IconButton
            aria-label={t("shared:inputs.checkboxes.addOption")}
            icon={<FaChevronRight />}
            onClick={handleNewOption}
          />
          <IconButton
            aria-label={t("shared:inputs.checkboxes.closeAddOption")}
            colorScheme="gray"
            icon={<FaTimes />}
            onClick={toggleShowNewOption}
          />
        </InputGroup>
      )}
    </>
  );
};

export default CheckboxesInput;
