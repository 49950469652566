import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaSortDown, FaSortUp } from "react-icons/fa";

import { CollectionContext } from "../../../shared/components/collection";
import { collectionOptsFromId } from "../../../shared/lib/ids";
import { camelize } from "../../../shared/lib/string";
import { CollectionOrderDirection } from "../../../types/serializers";

const CollectionSorts = () => {
  const { t } = useTranslation();
  const { collectionId, availableSorts, updateCollection } =
    useContext(CollectionContext);

  const params = collectionOptsFromId(collectionId);
  const currentOrders = (params.order ?? {}) as Record<
    string,
    CollectionOrderDirection
  >;
  const primaryOrder =
    Object.keys(currentOrders)[0] ?? availableSorts?.[0]?.attribute;
  const currentDirection =
    currentOrders[primaryOrder] ?? CollectionOrderDirection.Desc;
  const activeSortDirections = availableSorts?.find(
    (option) => option.attribute === camelize(primaryOrder)
  )?.directions;

  const handleOrderChange = (value: string) => {
    updateCollection({ order: { [value]: currentDirection } });
  };
  const handleDirectionChange = (direction: CollectionOrderDirection) => {
    updateCollection({ order: { [primaryOrder]: direction } });
  };

  if (!availableSorts) {
    return null;
  }

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            {t("shared:collection.sort")}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <RadioGroup value={camelize(primaryOrder)} onChange={handleOrderChange}>
          <VStack alignItems="left" ml={1}>
            {availableSorts?.map((option) => (
              <Radio key={option.attribute} value={option.attribute}>
                {option.label}
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
        {activeSortDirections && (
          <ButtonGroup isAttached marginTop="1em" size="sm" variant="outline">
            <Button
              borderRadius={0}
              isActive={currentDirection === CollectionOrderDirection.Desc}
              leftIcon={<FaSortDown />}
              marginRight="1em"
              variant="link"
              onClick={() =>
                handleDirectionChange(CollectionOrderDirection.Desc)
              }
            >
              {activeSortDirections?.[CollectionOrderDirection.Desc]}
            </Button>
            <Button
              borderRadius={0}
              isActive={currentDirection === CollectionOrderDirection.Asc}
              leftIcon={<FaSortUp />}
              variant="link"
              onClick={() =>
                handleDirectionChange(CollectionOrderDirection.Asc)
              }
            >
              {activeSortDirections?.[CollectionOrderDirection.Asc]}
            </Button>
          </ButtonGroup>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CollectionSorts;
