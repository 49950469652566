import React, { FC } from "react";
import { Route, useLocation, useParams } from "react-router-dom";

import {
  CollectionResource,
  DefinitionForType,
  ResourceType,
} from "../../types/serializers";
import { Loader } from "../components/loader";
import { Action, Section } from "../components/store/Router";
import { ResourceStatus } from "../components/store/Store";
import useFreshResource from "../hooks/useFreshResource";
import useStatus from "../hooks/useStatus";
import { resourceMapper, retrievePath } from "../lib/routes";
import { isLoadingStatus } from "../lib/status";

import useStore from "./useStore";

type RouteComponent<T> = FC<{ resource: T }>;

const WrappedResourcePage = <T,>({
  action,
  component: Page,
  resourceType,
  statusHandler: StatusHandler,
}: {
  action: Action;
  component: RouteComponent<T>;
  resourceType: ResourceType;
  statusHandler: FC<{ status: ResourceStatus }>;
}) => {
  const params = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { id, type } = resourceMapper[action](
    params,
    resourceType,
    queryParams
  );

  const resource = useFreshResource({ id, type });
  const status = useStatus({ id, type });
  const isLoading = isLoadingStatus(status);

  if (
    [
      ResourceStatus.Present,
      ResourceStatus.MarkedForRefresh,
      ResourceStatus.Refreshing,
    ].includes(status) ||
    isLoading
  ) {
    return isLoading ? <Loader /> : <Page resource={resource! as T} />;
  }

  return <StatusHandler status={status} />;
};

export const useResourceRouter = (
  statusHandler: FC<{ status: ResourceStatus }>,
  section?: Section
) => {
  const { router } = useStore();
  const generateRoute = <T extends ResourceType, A extends Action>(
    resourceType: T,
    action: A,
    component: RouteComponent<
      A extends Action.Index ? CollectionResource<T> : DefinitionForType<T>
    >
  ) => {
    const { id, type } = resourceMapper[action](
      { id: ":id" },
      resourceType,
      new URLSearchParams()
    );
    const path = retrievePath(router.resourceRoute({ id, type }, section));

    return (
      <Route
        element={
          <WrappedResourcePage
            action={action as Action}
            component={component}
            resourceType={resourceType}
            statusHandler={statusHandler}
          />
        }
        key={`${resourceType}-${action}`}
        path={path}
      />
    );
  };

  return generateRoute;
};
