import React from "react";
import { useTranslation } from "react-i18next";

import Link from "../../../shared/components/link";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import { EmptyResource } from "../../../types/serializers";
import useEditRoute from "../store/hooks/useEditRoute";
import { Section } from "../store/Router";

const EditResourceButton = ({ resource }: { resource: EmptyResource }) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const admin = currentUser?.attributes.admin;
  const editRoute = useEditRoute(resource, Section.Admin);

  if (!admin) {
    return null;
  }

  return (
    <Link
      href={editRoute}
      target={GLOBALS.section == "main" ? "_blank" : undefined}
    >
      {t("main:blogs.edit")}
    </Link>
  );
};

export default EditResourceButton;
