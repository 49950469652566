import React from "react";

import Link, { isExternal } from "../../../link";
import {
  ElementType,
  HTMLParser,
  LinkNode,
  LinkStyle,
  MarkdownSerializer,
  MarkedExtension,
  RenderElement,
  Tokens,
} from "../types";

const REGEX = /^({{?)([\s\S]*?[^}])(}}?)/;

const linkStyle = (match: string) => {
  switch (match) {
    case "{":
      return LinkStyle.Primary;
    case "{{":
      return LinkStyle.Secondary;
    default:
      return undefined;
  }
};

const linkSuffix = (token: Tokens.Generic) => {
  return [
    token.style && `data-link-style="${token.style}"`,
    isExternal(token.href) && 'target="_blank" rel="noopener noreferrer"',
  ]
    .filter(Boolean)
    .join(" ");
};

export const linkMarked: MarkedExtension = {
  level: "inline",
  name: ElementType.link,
  renderer(token): string {
    const { parser } = this;

    const link = parser.renderer.link(
      token.href,
      token.title ?? null,
      parser.parseInline(token.tokens ?? [], parser.renderer)
    );

    const suffix = linkSuffix(token);
    if (suffix === "") {
      return link;
    }

    return link.replace("<a ", `<a ${suffix} `);
  },
  start(src: string) {
    return src.match(REGEX)?.index;
  },
  tokenizer(src: string): void | Tokens.Generic {
    const match = src.match(REGEX);
    if (match) {
      const [token] = this.lexer.inlineTokens(
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        src.replace(match[0], `[${match[2]}]`)
      );

      return {
        ...token,
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        raw: token.raw.replace(`[${match[2]}]`, match[0]),
        style: linkStyle(match[1]),
        type: ElementType.link,
      };
    }

    return undefined;
  },
};

export const parseLinkHTML: HTMLParser = (el) => {
  const attrs = {
    href: el.attribs["href"],
    style: el.attribs["data-link-style"] ?? LinkStyle.Link,
    type: ElementType.link,
  } as LinkNode;
  const title = el.attribs["title"];
  if (title) {
    attrs["title"] = title;
  }

  return attrs;
};

const linkVariant = (style: LinkStyle) => {
  if (style == LinkStyle.Primary) {
    return "button";
  }

  if (style == LinkStyle.Secondary) {
    return "outlinedButton";
  }

  return "inlineText";
};

export const renderLink = (props: RenderElement) => {
  const { attributes, children, element, textEditor } = props;
  const { href, style, title } = element as LinkNode;
  const colorScheme = style == LinkStyle.Link ? undefined : "primary";
  const variant = linkVariant(style);
  const { ref, ...attributesWithoutRef } = attributes;

  return (
    <Link
      {...attributesWithoutRef}
      colorScheme={colorScheme}
      data-link-style={style}
      href={textEditor ? "" : href}
      title={title}
      variant={variant}
    >
      {children}
    </Link>
  );
};

export const linkMarkdown: MarkdownSerializer<LinkNode> = (chunk, children) => {
  switch (chunk.style) {
    case LinkStyle.Primary:
      return `{${children}}(${chunk.href || ""}${
        chunk.title ? ` "${chunk.title}"` : ""
      })`;
    case LinkStyle.Secondary:
      return `{{${children}}}(${chunk.href || ""}${
        chunk.title ? ` "${chunk.title}"` : ""
      })`;
    default:
      return `[${children}](${chunk.href || ""}${
        chunk.title ? ` "${chunk.title}"` : ""
      })`;
  }
};
