import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useRef,
  useState,
} from "react";

import { DispatchProps } from "../../hooks/useModalDispatch";

import ModalWrapper from "./ModalWrapper";

export interface ModalRenderSharedProps {
  handleClose: () => void;
}
export type ModalRenderProps = Record<string, unknown>;
export type ModalRenderer<P = ModalRenderProps> = FC<P> | undefined;
export type ModalState<P = ModalRenderProps> =
  | []
  | [
      modalComponent: ModalRenderer<P>,
      modalProps: DispatchProps<P>,
      closeOnOverlayClick: boolean
    ];
export type ModalDispatch<P = ModalRenderProps> = Dispatch<
  SetStateAction<ModalState<P>>
>;
export type ModalContext<P = ModalRenderProps> = [
  ModalDispatch<P>,
  RefObject<HTMLDivElement>
];

export const ModalContext = React.createContext<ModalContext>(
  {} as ModalContext
);

interface ModalContextProvider extends PropsWithChildren {
  initialValue?: ModalState;
  testMode?: boolean;
}

const ModalContextProvider = ({
  children,
  initialValue,
  testMode,
}: ModalContextProvider) => {
  const [[modalComponent, modalProps, closeOnOverlayClick], setModal] =
    useState<ModalState>(initialValue ?? []);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <ModalContext.Provider value={[setModal, containerRef]}>
      {children}
      <div ref={containerRef} />
      {(!testMode || modalComponent) && (
        <ModalWrapper
          closeOnOverlayClick={closeOnOverlayClick}
          modalComponent={modalComponent}
          modalProps={modalProps}
        />
      )}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
