import { useTranslation } from "react-i18next";

import { AvailableFilter, FilterType } from "../components/collection";
import categories from "../lib/categories";

const useBlogFilterOptions = (): AvailableFilter[] => {
  const { t } = useTranslation();

  return [
    {
      attribute: "categoryList",
      label: t("shared:blogs.categories"),
      options: categories.map((category) => ({
        label: category.label,
        value: category.label,
      })),
      type: FilterType.Checkboxes,
    },
  ];
};

export default useBlogFilterOptions;
