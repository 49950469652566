import { Box } from "@chakra-ui/react";
import React, { FC, PropsWithChildren } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import {
  CollectionAttributes,
  CollectionOrderDirection,
  CollectionRelationships,
  ResourceType,
} from "../../../types/serializers";
import useRefreshResource from "../../hooks/useRefreshResource";
import useResource from "../../hooks/useResource";
import useStatus from "../../hooks/useStatus";
import {
  CollectionOpts,
  collectionOptsFromQuery,
  getCollectionId,
} from "../../lib/ids";
import { isLoadingStatus } from "../../lib/status";
import useResourceRoute from "../store/hooks/useResourceRoute";

export enum FilterType {
  Checkboxes = "checkboxes",
  Select = "select",
}

export interface FilterOption {
  label: string;
  value: string;
}

export interface AvailableFilter {
  attribute: string;
  label: string;
  options?: FilterOption[];
  type: FilterType;
  useOptions?: () => FilterOption[];
}

export interface AvailableSort {
  attribute: string;
  directions?: Record<CollectionOrderDirection, string>;
  label: string;
}

export interface CollectionContext<T extends ResourceType = ResourceType> {
  alterCollectionId: (
    newParams: Partial<CollectionOpts<T>>
  ) => string | undefined;
  attributes: CollectionAttributes<T>;
  availableFilters?: AvailableFilter[];
  availableSorts?: AvailableSort[];
  collectionId: string;
  relationships: CollectionRelationships;
  updateCollection: (newParams: Partial<CollectionOpts<T>>) => void;
}

export const CollectionContext = React.createContext<CollectionContext>(
  {} as CollectionContext
);

export interface CollectionProps extends PropsWithChildren {
  availableFilters?: AvailableFilter[];
  availableSorts?: AvailableSort[];
  fresh?: boolean;
  fullPage?: boolean;
  id: string | undefined;
  onChange?: (id: string) => void;
  onLoad?: FC;
  wrapper?: FC;
  wrapperProps?: any;
}

const OPACITY = 0.5;

const Collection = <T extends ResourceType>({
  availableFilters,
  availableSorts,
  children,
  fresh,
  id,
  navigate,
  onChange,
  onLoad: OnLoad,
  wrapper: Wrapper = Box,
  wrapperProps,
}: CollectionProps & { navigate?: NavigateFunction }) => {
  const refreshResource = useRefreshResource();

  const [requestedCollectionId, setRequestedCollectionId] = React.useState(id);
  const requestedCollectionStatus = useStatus(
    {
      id: requestedCollectionId,
      type: ResourceType.Collection,
    },
    { fetch: true }
  );
  const [collectionId, setCollectionId] = React.useState(id);
  const { attributes, relationships } =
    useResource({
      id: collectionId,
      type: ResourceType.Collection,
    }) ?? {};

  React.useEffect(() => {
    if (fresh && requestedCollectionId) {
      refreshResource({
        id: requestedCollectionId,
        type: ResourceType.Collection,
      });
    }
  }, [fresh, requestedCollectionId]);
  React.useEffect(() => {
    setCollectionId(id);
    setRequestedCollectionId(id);
  }, [id]);
  const loading = requestedCollectionId !== collectionId;
  const route = useResourceRoute({
    id: requestedCollectionId,
    type: ResourceType.Collection,
  })!;
  React.useEffect(() => {
    if (
      loading &&
      requestedCollectionId &&
      !isLoadingStatus(requestedCollectionStatus)
    ) {
      setCollectionId(requestedCollectionId);
      if (onChange) {
        onChange(requestedCollectionId);
      }
      if (navigate) {
        navigate(route);
      }
    }
  }, [requestedCollectionId, requestedCollectionStatus]);

  const alterCollectionId = (newParams: Partial<CollectionOpts<T>>) => {
    if (attributes && collectionId) {
      const params = collectionOptsFromQuery<T>(
        new URLSearchParams(collectionId.split("?")[1])
      );

      return getCollectionId(attributes.itemType as T, {
        ...params,
        page: 1,
        ...newParams,
      });
    }

    return collectionId;
  };

  const updateCollection = (newParams: Partial<CollectionOpts<T>>) => {
    if (attributes && collectionId) {
      setRequestedCollectionId(alterCollectionId(newParams));
    }
  };

  if (OnLoad && isLoadingStatus(requestedCollectionStatus)) {
    return <OnLoad />;
  }

  if (!collectionId || !attributes || !relationships) {
    return null;
  }

  const opacityProps =
    Wrapper === Box ? { opacity: loading ? OPACITY : undefined } : {};

  return (
    <CollectionContext.Provider
      value={
        {
          alterCollectionId,
          attributes,
          availableFilters,
          availableSorts,
          collectionId,
          relationships,
          updateCollection,
        } as CollectionContext
      }
    >
      <Wrapper {...(opacityProps as any)} {...wrapperProps}>
        {children}
      </Wrapper>
    </CollectionContext.Provider>
  );
};

const CollectionFullPage = <T extends ResourceType>(props: CollectionProps) => {
  const navigate = useNavigate();

  return <Collection<T> {...props} navigate={navigate} />;
};

const CollectionWrapper = <T extends ResourceType>(props: CollectionProps) => {
  if (props.fullPage) {
    return <CollectionFullPage<T> {...props} />;
  }

  return <Collection {...props} />;
};

export default CollectionWrapper;
