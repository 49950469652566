import { useContext } from "react";

import {
  ModalContext,
  ModalRenderer,
} from "../components/modal/ModalContextProvider";

export type DispatchProps<P> = keyof Omit<P, "handleClose"> extends never
  ? void
  : Omit<P, "handleClose">;

function useModalDispatch<P>(
  component: ModalRenderer<P>,
  closeOnOverlayClick?: boolean
): (props: DispatchProps<P>) => void;
function useModalDispatch(
  component: undefined,
  closeOnOverlayClick?: boolean
): undefined;
function useModalDispatch<P>(
  component: ModalRenderer<P> | undefined,
  closeOnOverlayClick?: boolean
): (props: DispatchProps<P>) => void | undefined;
function useModalDispatch<P>(
  component: ModalRenderer<P> | undefined,
  closeOnOverlayClick?: boolean
) {
  const [setModal] = useContext(ModalContext) as ModalContext<P>;

  if (!component) {
    return undefined;
  }

  const dispatcher = (componentProps: DispatchProps<P>) => {
    setModal([component, componentProps, closeOnOverlayClick ?? true]);
  };

  return dispatcher;
}

export default useModalDispatch;
