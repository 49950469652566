import { EmptyResource } from "../../types/serializers";

import useStore from "./useStore";

const useRefreshResource = () => {
  const store = useStore();

  return (resource: EmptyResource, root?: string) => {
    store.refreshResource(resource.type, resource.id, root);
  };
};

export default useRefreshResource;
