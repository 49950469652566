import { useEffect, useMemo, useState } from "react";

import { FormFieldOption, FormFieldOptions } from "../../../../types/forms";
import { ResourceType } from "../../../../types/serializers";
import useCollection from "../../../hooks/useCollection";
import useResources from "../../../hooks/useResources";
import toString from "../../../lib/toString";
import { isString } from "../../../lib/typing";

type AddOption = (newOption: FormFieldOption) => void;

const useOptionsFromClass = (selectClass?: ResourceType) => {
  const collection = useCollection(selectClass);
  const records = useResources(collection?.relationships.items.data);

  return useMemo(() => {
    if (selectClass) {
      return records.map((record) => ({
        key: record?.id ?? "key-missing",
        label: toString(record),
      }));
    }

    return undefined;
  }, [records]);
};

const serializeOptions = (
  options?: string[] | FormFieldOption[] | undefined
) => {
  if (options) {
    return options.map((option) => {
      if (isString(option)) {
        return { key: option, label: option };
      }

      return option;
    });
  }

  return undefined;
};

const useOptions = (
  selectClass: ResourceType | undefined,
  options: FormFieldOptions | undefined,
  useOptionsHook?: () => FormFieldOptions
): [FormFieldOption[], AddOption] => {
  const optionsFromClass = useOptionsFromClass(selectClass);
  const [currentOptions, setOptions] = useState<FormFieldOption[]>(
    optionsFromClass ?? serializeOptions(options) ?? []
  );
  useEffect(() => {
    if (optionsFromClass && optionsFromClass !== currentOptions) {
      setOptions(optionsFromClass);
    }
  }, [optionsFromClass]);

  if (useOptionsHook) {
    return [serializeOptions(useOptionsHook()) ?? [], () => undefined];
  }

  const addOption = (newOption: FormFieldOption) =>
    setOptions((prev) => [...prev, newOption]);

  return [currentOptions, addOption];
};

export default useOptions;
