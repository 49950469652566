import React, { FC, ReactElement } from "react";

import {
  CollectionResource,
  DefinitionForType,
  ResourceType,
} from "../../../types/serializers";
import useResource from "../../hooks/useResource";
import { ResourceHandler } from "../async/AsyncResource";
import AsyncResources from "../async/AsyncResources";

import { CollectionContext } from "./index";

interface CollectionItemsProps<T extends ResourceType> {
  children: (
    props: DefinitionForType<T>,
    index?: number
  ) => ReactElement | null;
  onEmpty?: FC;
  onError?: ResourceHandler;
  onLoad?: ResourceHandler;
}

const CollectionItems = <T extends ResourceType>({
  children,
  onEmpty: OnEmpty,
  onError,
  onLoad,
}: CollectionItemsProps<T>) => {
  const { collectionId } = React.useContext(CollectionContext);
  const { relationships } =
    useResource<ResourceType.Collection, CollectionResource<T>>({
      id: collectionId,
      type: ResourceType.Collection,
    }) ?? {};

  if (OnEmpty && relationships?.items.data?.length === 0) {
    return <OnEmpty />;
  }

  return (
    <AsyncResources<T>
      resources={relationships?.items.data}
      onError={onError}
      onLoad={onLoad}
    >
      {children}
    </AsyncResources>
  );
};

export default CollectionItems;
