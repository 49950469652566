import { Checkbox } from "@chakra-ui/react";
import React from "react";

import { InputRenderer } from "../types";

const CheckboxInput = ({ label, inputProps }: InputRenderer) => {
  return (
    <Checkbox {...inputProps} isChecked={inputProps.value}>
      {label}
    </Checkbox>
  );
};

export default CheckboxInput;
