import { ResponsiveValue, chakra } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

import ErrorBoundary from "../errorHandler/ErrorBoundary";

const Main = ({
  children,
  marginBottom = "1em",
  marginTop = "1em",
  paddingX,
}: PropsWithChildren<{
  marginBottom?: ResponsiveValue<string | number>;
  marginTop?: ResponsiveValue<string | number>;
  paddingX?: ResponsiveValue<string | number>;
}>) => (
  <chakra.main
    marginBottom={marginBottom}
    marginTop={marginTop}
    minHeight="50vh"
    paddingX={paddingX}
  >
    <ErrorBoundary>{children}</ErrorBoundary>
  </chakra.main>
);

export default Main;
