import React, { ReactElement } from "react";

import {
  DefinitionForType,
  EmptyResource,
  ResourceType,
} from "../../../types/serializers";

import AsyncResource, { ResourceHandler } from "./AsyncResource";

interface AsyncResourcesProps<T extends ResourceType> {
  children: (
    props: DefinitionForType<T>,
    index?: number
  ) => ReactElement | null;
  onError?: ResourceHandler;
  onLoad?: ResourceHandler;
  resources: EmptyResource<T>[] | undefined;
}

const AsyncResources = <T extends ResourceType>({
  children,
  resources,
  onError,
  onLoad,
}: AsyncResourcesProps<T>) => (
  <React.Fragment>
    {resources?.map((item, itemIndex) => (
      <AsyncResource
        itemIndex={itemIndex}
        key={item.id}
        resource={item}
        onError={onError}
        onLoad={onLoad}
      >
        {(asyncResource, index) => children(asyncResource, index)}
      </AsyncResource>
    ))}
  </React.Fragment>
);

export default AsyncResources;
