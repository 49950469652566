import React, { Fragment } from "react";
import type { Descendant } from "slate";

import { renderElement } from "./renderElement";
import { renderLeaf } from "./renderLeaf";
import { isLeafNode } from "./types";

const renderNode = (element: Descendant, key: string): JSX.Element => {
  if (isLeafNode(element)) {
    return (
      <Fragment key={key}>
        {renderLeaf({
          attributes: {} as any,
          children: element.text,
          leaf: element,
          text: element,
        })}
      </Fragment>
    );
  }

  return (
    <Fragment key={key}>
      {renderElement({
        attributes: {} as any,
        children: element.children.map((child, index) =>
          renderNode(child, `${key}-${index}`)
        ),
        element,
      })}
    </Fragment>
  );
};

export const renderSlateArray = (value: Descendant[]) =>
  value.map((node, index) => renderNode(node, `node-${index}`));
