import { chakra } from "@chakra-ui/react";
import React from "react";

import VideoPlayer from "../../../videoPlayer";
import {
  ExtensionType,
  HTMLParser,
  MarkedExtension,
  RenderElement,
  Tokens,
  VideoNode,
} from "../types";

const REGEX = /^<video(\s*url="(?<url>[^"]*)")?><\/video>/;

export const videoMarked: MarkedExtension = {
  level: "block",
  name: ExtensionType.video,
  renderer(token): string {
    return token.raw;
  },
  start(src: string) {
    return src.match(REGEX)?.index;
  },
  tokenizer(src: string): void | Tokens.Generic {
    const match = src.match(REGEX);
    if (match) {
      return {
        raw: match[0],
        type: ExtensionType.video,
        url: match.groups?.url,
      };
    }

    return undefined;
  },
};

export const parseVideoHTML: HTMLParser = (el) => ({
  type: ExtensionType.video,
  url: el.attribs["url"],
});

export const renderVideo = (props: RenderElement) => {
  const { element } = props;
  const { url } = element as VideoNode;

  return (
    <chakra.div {...props.attributes} marginBottom="2em" maxWidth="100%">
      <VideoPlayer url={url} />
    </chakra.div>
  );
};

export const videoMarkdown = (chunk: VideoNode) => {
  const { url } = chunk;

  return `<video url="${url}"></video>\n`;
};
