import React from "react";

import { isElementNode } from "../../../../lib/typing";
import Image from "../../../image";
import {
  ElementType,
  HTMLParser,
  ImageNode,
  MarkdownSerializer,
  RenderElement,
} from "../types";

export const parseImageHTML: HTMLParser = (el) => ({
  alt: el.attribs["alt"],
  caption: el.attribs["title"],
  src: el.attribs["src"],
  type: ElementType.image,
});

export const parseFigureHTML: HTMLParser = (el) => {
  const img = el.children.find(
    (child) => isElementNode(child) && child.name === "img"
  );

  if (!isElementNode(img)) {
    return {};
  }

  return {
    alt: img.attribs["alt"],
    caption: img.attribs["title"],
    src: img.attribs["src"],
    type: ElementType.image,
  };
};

export const renderImageMarked = (
  href: string | null,
  title: string | null,
  text: string | null
) => {
  if (href === null) {
    return text;
  }
  let out = `<img alt="${text}" src="${href}"`;

  if (title) {
    out += ` title="${title}"><figcaption>${title}</figcaption>`;
  } else {
    out += ">";
  }

  return `<figure>${out}</figure>`;
};

export const renderImage = (props: RenderElement) => {
  const { element } = props;
  const { alt, caption, src } = element as ImageNode;

  return (
    <div {...props.attributes}>
      <Image alt={alt} caption={caption} marginBottom="2em" src={src} />
    </div>
  );
};

export const imageMarkdown: MarkdownSerializer<ImageNode> = (chunk) =>
  `![${chunk.alt}](${chunk.src || ""}${
    chunk.caption ? ` "${chunk.caption}"` : ""
  })\n`;
