import React from "react";

import BlogInline from "../../../blog/BlogInline";
import {
  BlogNode,
  ExtensionType,
  HTMLParser,
  MarkdownSerializer,
  MarkedExtension,
  RenderElement,
  Tokens,
} from "../types";

const REGEX = /^<blog(\s*slug="(?<slug>[^"]*)")?><\/blog>/;

export const parseBlogHTML: HTMLParser = (el) => ({
  slug: el.attribs["slug"],
  type: ExtensionType.blog,
});

export const blogMarked: MarkedExtension = {
  level: "block",
  name: ExtensionType.blog,
  renderer(token): string {
    return token.raw;
  },
  start(src: string) {
    return src.match(REGEX)?.index;
  },
  tokenizer(src: string): void | Tokens.Generic {
    const match = src.match(REGEX);
    if (match) {
      return {
        raw: match[0],
        slug: match.groups?.slug,
        type: ExtensionType.blog,
      };
    }

    return undefined;
  },
};

export const renderBlog = (props: RenderElement) => {
  const { element } = props;
  const { slug } = element as BlogNode;

  return (
    <div {...props.attributes}>
      <BlogInline slug={slug} />
      {props.children}
    </div>
  );
};

export const blogMarkdown: MarkdownSerializer<BlogNode> = (chunk: BlogNode) => {
  const { slug } = chunk;

  return `<blog slug="${slug ?? ""}"></blog>\n`;
};
