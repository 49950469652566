import React from "react";

import AsyncSelect from "../AsyncSelect";
import { InputRenderer } from "../types";

const SearchInput = ({
  inputProps,
  invalid,
  placeholder,
  selectClass,
}: InputRenderer) => {
  return (
    <AsyncSelect
      invalid={invalid}
      placeholder={placeholder}
      resourceType={selectClass!}
      {...inputProps}
    />
  );
};

export default SearchInput;
