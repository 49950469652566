import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import gfm from "remark-gfm";

const SimpleMarkdown = ({
  children,
  transformLinkUri,
}: {
  children: string;
  transformLinkUri?: (url: string) => string;
}) => (
  <Box
    __css={{
      "& ul, & ol": {
        marginBottom: "1em",
        paddingLeft: "2em",
      },
      a: {
        "&:hover": {
          color: "var(--chakra-colors-primary-dark)",
        },
        color: "var(--chakra-colors-primary-darkActive)",
        textDecoration: "underline",
      },
      p: {
        "&:last-child": {
          marginBottom: 0,
        },
        marginBottom: "1em",
      },
      table: { borderBottom: "1px solid #e7eaec", width: "100%" },
      "td, th": {
        "&:empty": {
          paddingX: 0,
          paddingY: 0,
        },
        paddingX: 3,
        paddingY: 2,
        textAlign: "left",
      },
      th: {
        borderTop: "1px solid #80808026",
        fontFamily: "body",
        fontSize: "1em",
        letterSpacing: "normal",
        lineHeight: 5,
        textTransform: "unset",
      },
      thead: {
        "& tr": {
          background: "#D9D9D9AA",
        },
      },
      tr: {
        "&:nth-of-type(even)": { background: "#D9D9D94C" },
      },
    }}
  >
    <ReactMarkdown
      linkTarget="_blank"
      remarkPlugins={[gfm, remarkBreaks]}
      transformLinkUri={transformLinkUri}
    >
      {children}
    </ReactMarkdown>
  </Box>
);

export default styled(SimpleMarkdown)`
  & a {
    text-decoration: underline;
  }
`;
