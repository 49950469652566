import { Container, SkeletonText } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect } from "react";

import Main from "../main/Main";

const RouteNotFound = () => {
  useEffect(() => {
    window.location.reload();
  }, []);

  return (
    <Main>
      <Container data-http-status={404} marginTop="2em" maxW="container.lg">
        <SkeletonText title={t("shared:status.loading")!} />
      </Container>
    </Main>
  );
};

export default RouteNotFound;
