import { Textarea } from "@chakra-ui/react";
import React from "react";

import { InputRenderer } from "../types";

const TextAreaInput = ({ inputProps, invalid, placeholder }: InputRenderer) => (
  <Textarea isInvalid={invalid} placeholder={placeholder} {...inputProps} />
);

export default TextAreaInput;
