import React from "react";

import Uploader from "../lib/uploader";

type UploadFile = (
  newFile: File,
  handleUploadFinished: (signedId: string) => void
) => void;

export type UseFileUpload = [
  uploadFile: UploadFile,
  progress: number | undefined,
  error: string | undefined
];

export const useFileUpload = (): UseFileUpload => {
  const [uploading, setUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleError = (err: Error) => {
    setUploading(false);
    setError(err.message);
    console.error(err);
  };

  const handleProgress = (e: ProgressEvent) => {
    if (e.lengthComputable) {
      setProgress((e.loaded / e.total) * 100);
    }
  };

  const uploadFile = React.useCallback<UploadFile>(
    (newFile, handleUploadFinished) => {
      setUploading(true);
      setProgress(0);

      const handleFinish = (signedId: string) => {
        setUploading(false);
        handleUploadFinished(signedId);
      };

      if (!GLOBALS.directUploadUrl) {
        throw new Error("No upload url present");
      }

      new Uploader(
        GLOBALS.directUploadUrl,
        handleProgress,
        handleFinish,
        handleError
      ).upload(newFile);
    },
    []
  );

  return [uploadFile, uploading ? progress : undefined, error];
};
