export function underscore(string: string): string;
export function underscore(string: undefined): undefined;
export function underscore(string?: string) {
  return string
    ?.replace(/([a-z\d])([A-Z]+)/g, "$1_$2")
    .replace(/[-\s]+/g, "_")
    .toLowerCase();
}

export function camelize(string: string): string;
export function camelize(string: undefined): undefined;
export function camelize(string?: string) {
  return string
    ?.split(/[-_]/g)
    ?.reduce(
      (camelString, subString) =>
        camelString + subString.charAt(0).toUpperCase() + subString.slice(1)
    );
}

export const singularize = (string: string) => string.slice(0, -1);
