import { SkeletonText } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export const Loader = ({ width }: { width?: string }) => {
  const { t } = useTranslation();

  return (
    <SkeletonText
      margin="auto"
      maxWidth="85em"
      noOfLines={5}
      paddingBottom="2em"
      paddingTop="3em"
      paddingX="2em"
      skeletonHeight="4"
      spacing="4"
      title={t("shared:status.loading")!}
      width={width}
    />
  );
};
