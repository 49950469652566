import { Modal, ModalOverlay } from "@chakra-ui/react";
import React, { Suspense, useContext } from "react";

import {
  ModalContext,
  ModalRenderProps,
  ModalRenderer,
} from "./ModalContextProvider";

export const MODAL_TEST_ID = "modal-wrapper";

interface ModalWrapperProps {
  closeOnOverlayClick?: boolean;
  modalComponent?: ModalRenderer;
  modalProps?: ModalRenderProps;
}

const ModalWrapper = ({
  closeOnOverlayClick,
  modalComponent: Component,
  modalProps,
}: ModalWrapperProps) => {
  const [setModal] = useContext(ModalContext);
  const handleClose = () => {
    setModal([]);
  };

  return (
    <Modal
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={!!Component}
      onClose={handleClose}
    >
      <ModalOverlay />
      <Suspense fallback={<div>Loading...</div>}>
        <div data-testid={MODAL_TEST_ID}>
          {Component && <Component handleClose={handleClose} {...modalProps} />}
        </div>
      </Suspense>
    </Modal>
  );
};

export default ModalWrapper;
