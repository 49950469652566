import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import type { Text } from "slate";

import { Renderer } from "../renderElement";
import {
  BlockType,
  HTMLParser,
  MarkdownSerializer,
  TableCellNode,
  TableHeadNode,
  isElement,
} from "../types";

export const parseTableHead: HTMLParser = (_el, children) => {
  const content = children
    .filter(isElement)
    .map((row) =>
      row.children
        .filter(isElement)
        .map((cell) =>
          cell.children.filter((leaf) => (leaf as Text).text !== "").flat()
        )
        .flat()
    )
    .flat();

  return {
    display: content.length > 0,
    type: BlockType.tableHead,
  } as TableHeadNode;
};

export const parseTableCell =
  (type: BlockType.tableCell | BlockType.tableHCell): HTMLParser =>
  (el) => {
    const node: Partial<TableCellNode> = { type };

    if (el.attribs["colspan"]) {
      const colSpan = Number.parseInt(el.attribs["colspan"]);
      if (colSpan > 1) {
        node.colSpan = colSpan;
      }
    }

    return node;
  };

export const renderTable: Renderer = ({ attributes, children }) => (
  <TableContainer
    {...attributes}
    backgroundAttachment="local, local, scroll, scroll"
    backgroundImage="linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)), linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))"
    backgroundPosition="left center, right center, left center, right center"
    backgroundRepeat="no-repeat"
    backgroundSize="40px 100%, 40px 100%, 14px 100%, 14px 100%"
    fontSize={14}
    marginBottom="2em"
  >
    <Table size="sm" variant="blog">
      {children}
    </Table>
  </TableContainer>
);

export const renderTableBody: Renderer = ({ attributes, children }) => (
  <Tbody {...attributes}>{children}</Tbody>
);

export const renderTableCell =
  (Cell: typeof Td | typeof Th, editor: boolean): Renderer =>
  // eslint-disable-next-line react/display-name
  ({ attributes, children, element }) =>
    (
      <Cell
        border={editor ? "1px solid lightgray" : undefined}
        colSpan={(element as TableCellNode).colSpan}
        paddingX={{ base: 3, md: 6 }}
        paddingY={{ base: 1, md: 2 }}
        whiteSpace="pre-wrap"
        {...attributes}
      >
        {children}
      </Cell>
    );

export const renderTableHead: Renderer = ({
  attributes,
  children,
  element,
}) => {
  return (
    <Thead
      display={!(element as TableHeadNode).display ? "none" : undefined}
      {...attributes}
    >
      {children}
    </Thead>
  );
};

export const renderTableRow: Renderer = ({ attributes, children }) => (
  <Tr {...attributes}>{children}</Tr>
);

const tableCellMarkdown: MarkdownSerializer<TableCellNode> = (
  chunk,
  children
) => {
  if (chunk.colSpan && chunk.colSpan > 1) {
    const placeholders = Array.from(Array(chunk.colSpan - 1))
      .map(() => " --- |")
      .join("");

    return `\\${chunk.colSpan} ${children} |${placeholders}`;
  }

  return ` ${children} |`;
};

export const tableMarkdown: Record<string, MarkdownSerializer<any>> = {
  [BlockType.table]: (_chunk, children) => `${children}\n`,
  [BlockType.tableBody]: (_chunk, children) => `${children}\n`,
  [BlockType.tableCell]: tableCellMarkdown,
  [BlockType.tableHCell]: tableCellMarkdown,
  [BlockType.tableHead]: (_chunk, children) =>
    `${children}${children.replace(/([^|\n]+)/g, " --- ")}`,
  [BlockType.tableRow]: (_chunk, children) => `|${children}\n`,
};

export const renderTableCellMarked = (
  content: string,
  flags: { header: boolean }
) => {
  if (content === "---") {
    return "";
  }

  const type = flags.header ? "th" : "td";
  const colSpanMatch = content.match(/(\\(?<colSpan>\d)+ )?(?<content>.+)/);
  const colSpan = Number.parseInt(colSpanMatch?.groups?.colSpan ?? "0");
  const tag = colSpan > 1 ? `<${type} colspan="${colSpan}">` : `<${type}>`;
  const newContent = colSpanMatch?.groups?.content ?? content;

  return tag + newContent + `</${type}>\n`;
};
