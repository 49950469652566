import { useTranslation } from "react-i18next";

import { CollectionOrderDirection } from "../../types/serializers";

const useBlogSortOptions = () => {
  const { t } = useTranslation();

  return [
    {
      attribute: "relevance",
      label: t("shared:blogs.sort.relevance.label"),
    },
    {
      attribute: "publishedAt",
      directions: {
        [CollectionOrderDirection.Asc]: t("shared:blogs.sort.publishedAt.asc"),
        [CollectionOrderDirection.Desc]: t(
          "shared:blogs.sort.publishedAt.desc"
        ),
      },
      label: t("shared:blogs.sort.publishedAt.label"),
    },
    {
      attribute: "pageViews",
      directions: {
        [CollectionOrderDirection.Asc]: t("shared:blogs.sort.pageViews.asc"),
        [CollectionOrderDirection.Desc]: t("shared:blogs.sort.pageViews.desc"),
      },
      label: t("shared:blogs.sort.pageViews.label"),
    },
  ];
};

export default useBlogSortOptions;
