import { chakra } from "@chakra-ui/react";
import React from "react";

import { RenderElement } from "../types";

export const renderBlockquote = ({ attributes, children }: RenderElement) => (
  <chakra.blockquote
    __css={{
      "&:before": {
        color: "#ccc",
        content: "open-quote",
        fontFamily: "Arial",
        fontSize: "4em",
        lineHeight: "0.1em",
        marginRight: "0.25em",
        verticalAlign: "-0.4em",
      },
    }}
    background=" #f9f9f9"
    borderLeft="5px solid #ccc"
    margin="1.5em 10px"
    padding="0.5em 10px"
    {...attributes}
  >
    {children}
  </chakra.blockquote>
);
