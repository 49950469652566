import { Select } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import useOptions from "../hooks/useOptions";
import { InputRenderer } from "../types";

const SelectInput = ({
  inputProps,
  invalid,
  options,
  placeholder,
  selectClass,
}: InputRenderer) => {
  const { t } = useTranslation();

  const [selectOptions] = useOptions(selectClass, options);

  return (
    <Select isInvalid={invalid} placeholder={placeholder} {...inputProps}>
      <option disabled={inputProps.value} value="">
        {t("admin:inputs.select.defaultOption")}
      </option>
      {selectOptions.map((option) => (
        <option key={option.key} value={option.key}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default SelectInput;
