import { Skeleton } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import {
  DefinitionForType,
  EmptyResource,
  ResourceType,
} from "../../../types/serializers";
import useResource from "../../hooks/useResource";
import useStatus from "../../hooks/useStatus";
import { isLoadingStatus } from "../../lib/status";
import { ResourceStatus } from "../store/Store";

export type ResourceHandler = FunctionComponent<{
  itemIndex?: number;
  resource: Partial<EmptyResource> | undefined;
}>;

interface AsyncResourceProps<T extends ResourceType> {
  children: FunctionComponent<DefinitionForType<T>>;
  itemIndex?: number;
  onError?: ResourceHandler;
  onLoad?: ResourceHandler;
  resource: Partial<EmptyResource<T>> | undefined;
}

const DefaultLoader = () => {
  const { t } = useTranslation();

  return <Skeleton height="1em" title={t("shared:status.loading")!} />;
};
const DefaultErrorHandler = () => {
  const { t } = useTranslation();

  return <span>{t("shared:status.error")}</span>;
};

const AsyncResource = <T extends ResourceType>({
  children,
  itemIndex,
  resource,
  onError,
  onLoad,
}: AsyncResourceProps<T>) => {
  const OnError = onError ?? DefaultErrorHandler;
  const OnLoad = onLoad ?? DefaultLoader;
  const status = useStatus(resource);
  const item = useResource<T>(resource);

  if (!resource) {
    return null;
  }

  if (isLoadingStatus(status)) {
    return <OnLoad itemIndex={itemIndex} resource={resource} />;
  }
  if (
    item &&
    [
      ResourceStatus.Present,
      ResourceStatus.MarkedForRefresh,
      ResourceStatus.Refreshing,
    ].includes(status)
  ) {
    return children(item, itemIndex);
  }

  return <OnError itemIndex={itemIndex} resource={resource} />;
};

export default AsyncResource;
