import { chakra } from "@chakra-ui/react";
import { Widget } from "@typeform/embed-react";
import React from "react";

import {
  ExtensionType,
  HTMLParser,
  MarkedExtension,
  RenderElement,
  Tokens,
  TypeformNode,
} from "../types";

const REGEX = /^<typeform(\s+formId="(?<formId>[^"]*)")?><\/typeform>/;

export const typeformMarked: MarkedExtension = {
  level: "block",
  name: ExtensionType.typeform,
  renderer(token): string {
    return token.raw;
  },
  start(src: string) {
    return src.match(REGEX)?.index;
  },
  tokenizer(src: string): void | Tokens.Generic {
    const match = src.match(REGEX);
    if (match) {
      return {
        formId: match.groups?.formId,
        raw: match[0],
        type: ExtensionType.typeform,
      };
    }

    return undefined;
  },
};

export const parseTypeformHTML: HTMLParser = (el) => ({
  formId: el.attribs["formid"] ?? "",
  type: ExtensionType.typeform,
});

export const renderTypeform = (props: RenderElement) => {
  const { element } = props;
  const { formId } = element as TypeformNode;

  return (
    <chakra.div {...props.attributes} marginBottom="2em">
      <Widget height={600} id={formId} />
    </chakra.div>
  );
};

export const typeformMarkdown = (chunk: TypeformNode) => {
  const { formId } = chunk;

  return "<typeform " + `formId="${formId ?? ""}"></typeform>\n`;
};
