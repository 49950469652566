import { LinkBox, chakra } from "@chakra-ui/react";
import { ResponsiveValue } from "@chakra-ui/styled-system";
import React from "react";

import Link from "../link";
import { PensionProviders } from "../richText/lib/elements/pensionProviders";

interface PensionProviderProps {
  attributes?: Record<string, string | boolean>;
  borderColor?: string;
  marginBottom?: ResponsiveValue<string | number>;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  padding?: ResponsiveValue<string | number>;
  provider: string;
  url?: string;
}

const PensionProvider = ({
  attributes,
  borderColor,
  marginBottom,
  onClick,
  padding,
  provider,
  url,
}: PensionProviderProps) => {
  if (!Object.keys(PensionProviders).includes(provider)) {
    throw `Pension provider ${provider} does not exist`;
  }

  const { label, image } = PensionProviders[provider];

  return (
    <LinkBox
      _hover={{
        background: "primary.50",
      }}
      alignItems="center"
      border="1px"
      borderColor={borderColor ?? "gray.light"}
      borderRadius="0.5em"
      display="flex"
      flexBasis="49%"
      justifyContent="center"
      marginBottom={marginBottom ?? { base: "0.5em", md: "1em" }}
      padding={padding ?? { base: "1em", md: "2em" }}
      title={label}
      onClick={onClick}
      {...(attributes ?? {})}
    >
      <Link overlay href={url}>
        <chakra.img alt={label} maxHeight="5em" src={image} />
      </Link>
    </LinkBox>
  );
};

export default PensionProvider;
