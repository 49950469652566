import { Progress, chakra } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FaFile } from "react-icons/fa";

import Button from "../../button";
import { useFileUpload } from "../hooks/useFileUpload";
import { InputRenderer } from "../types";

interface FileInputPreview {
  isDragActive: boolean;
  progress: number | undefined;
  value: string;
}

export const FILE_INPUT_TID = "file-input-tid";

const FileInputPreview = ({
  isDragActive,
  progress,
  value,
}: FileInputPreview) => {
  const { t } = useTranslation();

  if (isDragActive) {
    return <p>Drop the files here ...</p>;
  }

  if (typeof progress !== "undefined") {
    return <Progress max={100} min={0} value={progress} />;
  }

  if (value) {
    const previewUrl = value.startsWith("http")
      ? value
      : GLOBALS.previewTemplate?.replace(":signed_id", value);

    return (
      <chakra.img
        maxHeight={300}
        src={previewUrl}
        title={t("admin:inputs.file.clickToChange")!}
      />
    );
  }

  return (
    <p>
      <Button leftIcon={<FaFile />}>Upload</Button>
    </p>
  );
};

const FileInput = ({ inputProps: { onChange, value } }: InputRenderer) => {
  const [uploadFile, progress, error] = useFileUpload();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const [acceptedFile] = acceptedFiles;

    uploadFile(acceptedFile, onChange);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} data-testid={FILE_INPUT_TID} />
      <FileInputPreview
        isDragActive={isDragActive}
        progress={progress}
        value={value}
      />
      {error}
    </div>
  );
};

export default FileInput;
