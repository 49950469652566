import { nl } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";

export const formatDate = (string: string) =>
  formatInTimeZone(Date.parse(string), "Europe/Amsterdam", "dd MMMM yyyy", {
    locale: nl,
  });

export const formatDateTime = (string: string, format?: string) =>
  formatInTimeZone(
    Date.parse(string),
    "Europe/Amsterdam",
    format ?? "dd-MM-yyyy, HH:mm",
    {
      locale: nl,
    }
  );
