import { Accordion } from "@chakra-ui/react";
import React, { useContext } from "react";

import CollectionFilters from "./CollectionFilters";
import CollectionSorts from "./CollectionSorts";

import { CollectionContext } from "./index";

const CollectionSortFilter = () => {
  const { availableFilters } = useContext(CollectionContext);
  const defaultIndex = [
    0,
    ...(availableFilters ?? []).map((_, index) => index + 1),
  ];

  return (
    <Accordion allowMultiple defaultIndex={defaultIndex} variant="basic">
      <CollectionSorts />
      <CollectionFilters accordion />
    </Accordion>
  );
};

export default CollectionSortFilter;
