import { Link, chakra } from "@chakra-ui/react";
import React, { MouseEventHandler, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ResourceType } from "../../../types/serializers";
import useResourceRoute from "../store/hooks/useResourceRoute";

import { CollectionContext } from "./index";

const OFFSET = 2;
const SECOND_PAGE = 2;
const COLLAPSE_PLACEHOLDER = null;

const PaginationButton = ({
  children,
  label,
  page,
}: {
  children: string;
  label?: string;
  page: number;
}) => {
  const {
    attributes: { page: currentPage, pageSize, totalCount },
    alterCollectionId,
    updateCollection,
  } = useContext(CollectionContext);
  const pageCount = Math.ceil(totalCount / pageSize);
  const active = page == currentPage;
  const newId = alterCollectionId({ page });
  const route = useResourceRoute({ id: newId, type: ResourceType.Collection });

  if (!newId) {
    return null;
  }

  const onClick: MouseEventHandler = (e) => {
    e.preventDefault();

    updateCollection({ page });
  };
  const disabled = page <= 0 || page > pageCount;

  return (
    <chakra.li display="inline-block" listStyleType="none">
      <Link
        aria-current={active || undefined}
        aria-disabled={disabled || undefined}
        aria-label={label}
        href={disabled ? undefined : route}
        variant="paginationButton"
        onClick={disabled ? undefined : onClick}
      >
        {children}
      </Link>
    </chakra.li>
  );
};

export const getPaginationButtons = (page: number, pageCount: number) => {
  const SECOND_LAST_PAGE = pageCount - 1;
  const buttons: Array<number | null> = [1];
  const from = Math.max(SECOND_PAGE, page - OFFSET);
  const to = Math.min(SECOND_LAST_PAGE, page + OFFSET);
  if (from > SECOND_PAGE) {
    buttons.push(COLLAPSE_PLACEHOLDER);
  }
  for (let n = from; n <= to; n++) {
    buttons.push(n);
  }
  if (to < SECOND_LAST_PAGE) {
    buttons.push(COLLAPSE_PLACEHOLDER);
  }
  buttons.push(pageCount);

  return buttons;
};

const CollectionPagination = () => {
  const { t } = useTranslation();
  const {
    attributes: { page, pageSize, totalCount },
  } = useContext(CollectionContext);
  const pageCount = Math.ceil(totalCount / pageSize);

  if (pageCount <= 1) {
    return null;
  }

  const buttons = getPaginationButtons(page, pageCount);

  return (
    <chakra.nav
      aria-label={t("shared:collection.pagination.label")!}
      marginTop="1em"
      role="navigation"
      textAlign="center"
    >
      <ul>
        <PaginationButton
          label={t("shared:collection.pagination.previousPage")!}
          page={page - 1}
        >
          {"<"}
        </PaginationButton>
        {buttons.map((button, index) =>
          button ? (
            <PaginationButton
              key={`${index}-${button}`}
              label={
                t("shared:collection.pagination.goToPage", { page: button })!
              }
              page={button}
            >
              {button.toString()}
            </PaginationButton>
          ) : (
            <span key={`${index}-${button}`}>...</span>
          )
        )}
        <PaginationButton
          label={t("shared:collection.pagination.nextPage")!}
          page={page + 1}
        >
          {">"}
        </PaginationButton>
      </ul>
    </chakra.nav>
  );
};

export default CollectionPagination;
