import {
  Checkbox,
  CheckboxGroup,
  Select,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import React from "react";

import { AvailableFilter, FilterType } from "./index";

interface CollectionFilterProps extends AvailableFilter {
  onChange: (value: string[]) => void;
  value: string[];
}

const CollectionFilter = ({
  options: optionsProp,
  type,
  value,
  onChange,
  useOptions,
}: CollectionFilterProps) => {
  const options = useOptions ? useOptions() : optionsProp;

  switch (type) {
    case FilterType.Checkboxes:
      return (
        <CheckboxGroup value={value} onChange={onChange}>
          <Stack direction="column" ml={1}>
            {options?.map((option) => (
              <Checkbox key={option.value} value={option.value}>
                {option.label}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      );
      break;
    case FilterType.Select:
      if (!options || options.length === 0) {
        return <Skeleton height="1em" marginY="1em" width="100%" />;
      }

      return (
        <Select
          as="select"
          value={value[0]}
          onChange={(e) => onChange([e.target.value])}
        >
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      );
      break;
    default:
      throw new Error(`Unknown type: ${type}`);
  }
};

export default CollectionFilter;
