const getStore = (persist?: boolean) => {
  try {
    return persist ? localStorage : sessionStorage;
  } catch {
    return undefined;
  }
};

export const getSessionStorage = (key: string, persist?: boolean) => {
  return getStore(persist)?.getItem(key);
};

export const setSessionStorage = (
  key: string,
  value: string,
  persist?: boolean
) => {
  return getStore(persist)?.setItem(key, value);
};

export const removeSessionStorage = (key: string, persist?: boolean) => {
  return getStore(persist)?.removeItem(key);
};
