import { As, Heading } from "@chakra-ui/react";
import { Element } from "domhandler";
import React from "react";

import {
  ElementType,
  HeadingNode,
  MarkdownSerializer,
  RenderElement,
  Token,
} from "../types";

const HeadingLevels: Record<string, ElementType> = {
  h1: ElementType.headingOne,
  h2: ElementType.headingOne,
  h3: ElementType.headingTwo,
  h4: ElementType.headingThree,
  h5: ElementType.headingThree,
  h6: ElementType.headingThree,
};

const HeadingVariants: Record<string, string> = {
  h2: "md",
  h3: "sm",
  h4: "xs",
};

const HeadingPrefixes: Record<string, string> = {
  [ElementType.headingOne]: "#",
  [ElementType.headingTwo]: "##",
  [ElementType.headingThree]: "###",
};

export const parseHeadingHTML = (el: Element) => ({
  id: el.attribs["id"],
  type: HeadingLevels[el.name],
});

export const renderHeading = (level: string) => {
  const heading = `h${level}`;

  const Comp = (props: RenderElement) => (
    <Heading
      as={heading as As}
      id={(props.element as HeadingNode).id}
      variant={HeadingVariants[heading]}
      {...props.attributes}
    >
      {props.children}
    </Heading>
  );
  Comp.displayName = `Heading${level}`;

  return Comp;
};

export const headingMarkdown: MarkdownSerializer = (chunk, children) =>
  `${HeadingPrefixes[chunk.type]} ${children}\n`;

export const walkHeading = (token: Token) => {
  if (token.type === "heading") {
    token.depth += 1;
  }
};
