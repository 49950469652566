import { ResourceType } from "../../../../types/serializers";
import useStore from "../../../hooks/useStore";
import { CollectionOpts } from "../../../lib/ids";
import { Section } from "../Router";

const useIndexRoute = <T extends ResourceType = ResourceType>(
  type: T,
  opts: CollectionOpts<T> | undefined = undefined,
  section: Section = GLOBALS.section
): string => {
  const store = useStore();

  return store.router.indexRoute(type, opts, section)!;
};

export default useIndexRoute;
